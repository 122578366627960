import { createSignal, mergeProps, createEffect, splitProps, onMount } from 'solid-js'
import { createContext, useContext } from 'solid-js';
import { createStore } from 'solid-js/store'
import sessionStore from '../../../../stores/session-store.js'
import userStore from '../../../../stores/user-store.js'
import PropertyService from '../../../services/property-service'
import BookingService from '../../../services/booking-service'
import countStore from '../../../../stores/count-store.js'
import { useLocation, useNavigate } from '@solidjs/router'

export const PublicTemplateContext = createContext({
    isContextReady: () => false
});

export const usePublicTemplateComponentController = (props) => {

    const [reqAttrs, styles, attrs] = splitProps(mergeProps({
        showSidebar: false,
        showHeader: false,
        showHeaderMenu: false,
        showFooter: false,
        overflow: false
    }, props), ['logo'], ['class'])

    const navigate = useNavigate()

    const location = useLocation()

    const [showHumburgetMenu, setshowHumburgetMenu] = createSignal();
    const [isAuthenticated, setIsAuthenticated] = createSignal(sessionStore.getAccessToken());

    const toggleHumburgerMenu = () => {
        setshowHumburgetMenu(prev => !prev)
    }

    console.log('usePublicTemplateComponentController')

    const [state, setState] = createStore({
        showHeader: false,
        showHeaderMenu: false,
        showFooter: false,
        showSidebar: false,
        isContextReady: false
    });

    console.log('usePublicTemplateComponentController - state', state)

    const value = {
        state,
        setShowHeader: (value) => setState('showHeader', value),
        setShowHeaderMenu: (value) => setState('showHeaderMenu', value),
        setShowFooter: (value) => setState('showFooter', value),
        setShowSidebar: (value) => setState('showSidebar', value),
        updateAuthenticatedData: () => updateAuthenticatedData(),
        isContextReady: () => true,
    };

    const logout = async () => {
        try {
            sessionStore.clear()
            userStore.clear()
            navigate('/login')

        } catch (error) {
            console.error(error);
            errorStore.addError(new Error({ message: error.message, stack: 'Logout in sidebarNav' }));
        }
    }


    let properties = []
    let stays = []
    let bookings = []

    onMount(async () => {
        createEffect(async () => {
            const accessToken = await sessionStore.getAccessToken();
            await setIsAuthenticated(accessToken ? true : false);

            if (accessToken) {
                await updateAuthenticatedData();  // Call the method on mount if authenticated
            }
        });
        countStore.set({ isLoaded: true })
    })

    const updateAuthenticatedData = async () => {
        try {
            const properties = await PropertyService.list();
            if (properties) {
                countStore.set({ numOfProperties: properties.filter(p => p.connection_status).length });
            }

            const stays = await BookingService.listByProfileId();
            if (stays.bookingList) {
                countStore.set({ numOfStays: stays.bookingList.length });
            }

            const bookings = await BookingService.listByPropertiesProfileId();
            if (bookings.bookingList) {
                countStore.set({ numOfBookings: bookings.bookingList.length });
            }

            countStore.set({ isLoaded: true });
        } catch (error) {
            console.error('Error fetching authenticated data:', error);
            errorStore.addError(new Error({ message: error.message, stack: 'updateAuthenticatedData' }));
        }
    };

    const getMenuItemProps = (path) => {
        return {
            href: path,
            class: 'public-template-component__main__sidebar__menu__item',
            classList: { 'public-template-component__main__sidebar__menu__item--selected': location.pathname === path }
        }
    }

    console.log('attrs', attrs);
    return {
        reqAttrs,
        styles,
        countStore,
        attrs,
        logout,
        properties,
        stays,
        state,
        value,
        showHumburgetMenu,
        isAuthenticated,
        toggleHumburgerMenu,
        sessionStore,
        getMenuItemProps
    }
}
