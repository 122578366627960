import BookingAPI from "../data/booking-api-client";

export default class BookingService {
    static async create(bookingData) {
        try {
            const { data: { booking_id } } = await BookingAPI.create(bookingData);
            const { data: booking } = await BookingAPI.show(booking_id);
            return booking;
        } catch (error) {
            throw error;
        }
    }

    static async listByPropertiesProfileId() {
        try {
            const { data: bookings } = await BookingAPI.listByPropertiesProfileId();
            return bookings || [];
        } catch (error) {
            throw error;
        }
    }

    static async listByProfileId() {
        try {
            const { data: bookings } = await BookingAPI.listByProfileId();
            return bookings || [];
        } catch (error) {
            throw error;
        }
    }

    static async show(id) {
        try {
            const { data: bookings } = await BookingAPI.show(id)
            return bookings
        } catch (error) {
            console.error('BookingAPI.show - Error: ', error)
            throw error
        }
    }


    static async update(bookingId, bookingData) {
        try {
            if (!bookingId) {
                throw new TypeError('Invalid booking id');
            }

            await BookingAPI.update(bookingId, bookingData);
            const { data: updatedBooking } = await BookingAPI.show(bookingId);

            return updatedBooking;
        } catch (error) {
            throw error;
        }
    }

    static async cancel(bookingId) {
        try {
            return await BookingAPI.cancel(bookingId)
        } catch (error) {
            throw error;
        }
    }

    static async accept(bookingId) {
        try {
            return await BookingAPI.accept(bookingId)
        } catch (error) {
            throw error;
        }
    }

    static async reject(bookingId) {
        try {
            return await BookingAPI.reject(bookingId)
        } catch (error) {
            throw error;
        }
    }

    static async contact(message, bookingId) {
        try {
            if (!bookingId || bookingId === '') throw new Error('No booking_id provided')
            if (!message || message === '') throw new Error('No message provided')
            return await BookingAPI.contact(message, bookingId)
        } catch (error) {
            console.error(`Error sending message to recipient of booking with ID: ${bookingId} - `, error);
            throw error;
        }
    }

    static async updatePrice(bookingId, bookingUrl) {
        try {
            if (!bookingId) {
                throw new TypeError('Invalid booking id');
            }

            await BookingAPI.updatePrice(bookingId, bookingUrl);
            const { data: updatedBooking } = await BookingAPI.show(bookingId);

            return updatedBooking;
        } catch (error) {
            throw error;
        }
    }

    static async pay(bookingId, confirmationId) {
        try {
            console.log('BookingService.pay - bookingId: ', { bookingId, confirmationId })
            return await BookingAPI.pay(bookingId, confirmationId)
        } catch (error) {
            throw error;
        }
    }

    static async search(bookingData) {
        try {
            const { data } = await BookingAPI.search(bookingData)

            return data[0] 
        } catch (error) {
            throw error
        }
    }

    static async checkDuplicate(bookingData) {
        try {
            const { data } = await BookingAPI.checkDuplicate(bookingData)

            return data[0]
        } catch (error) {
            throw error
        }
    }
}
