export const debounceAsync = (func, delay) => {
    let timeoutId

    return async function (...args) {
        return new Promise((resolve) => {
            clearTimeout(timeoutId)

            timeoutId = setTimeout(async () => {
                const resultValue = await func.apply(this, args)
                resolve(resultValue)
            }, delay)
        })
    }
}

// convert to title case for all words not using regex
export const toTitleCase = (str) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
}

export const generateDaysArray = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Set the time to midnight to ignore time considerations
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);

    const daysArray = [];

    for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        daysArray.push(new Date(dt)); // Push the date or a specific format of the date
    }

    return daysArray;
};

export const generateTimeList = () => {
    try {
        const times = [];
        let startTime = new Date();
        startTime.setHours(0, 0, 0, 0); // Set start time to 12:00 AM

        // Loop for 96 increments (24 hours * 4 increments per hour = 96)
        for (let i = 0; i < 96; i++) {
            const hours = startTime.getHours();
            const minutes = startTime.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const formattedHours = hours % 12 === 0 ? 12 : hours % 12; // Convert 24-hour format to 12-hour format
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

            times.push(`${formattedHours}:${formattedMinutes} ${ampm}`);

            startTime.setMinutes(startTime.getMinutes() + 15); // Increment by 15 minutes
        }

        return times;
    } catch (error) {
        console.error(error)
        errorStore.addError(new Error({ message: error.message, stack: 'generateTimeList in usePropertyPageController' }))
    }
}


export const formatTime = (dateString) => {
    const date = new Date(dateString);

    const options = {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    };

    return new Intl.DateTimeFormat('en-US', options).format(date).toLowerCase();
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        weekday: 'short'
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);
}

export const formatDateTime = (dateString) => {
    return `${formatTime(dateString)} • ${formatDate(dateString)}`;
}

export const formatDateRange = (checkIn, checkOut) => {
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);

    const options = {
        month: 'short',
        day: '2-digit'
    };

    const formattedCheckIn = new Intl.DateTimeFormat('en-US', options).format(checkInDate);
    const formattedCheckOut = new Intl.DateTimeFormat('en-US', options).format(checkOutDate);

    if (checkInDate.getFullYear() === checkOutDate.getFullYear()) {
        return `${formattedCheckIn} – ${formattedCheckOut}, ${checkInDate.getFullYear()}`;
    } else {
        const yearOptions = { ...options, year: 'numeric' };
        const formattedCheckInWithYear = new Intl.DateTimeFormat('en-US', yearOptions).format(checkInDate);
        const formattedCheckOutWithYear = new Intl.DateTimeFormat('en-US', yearOptions).format(checkOutDate);
        return `${formattedCheckInWithYear} – ${formattedCheckOutWithYear}`;
    }
}

export const statusPrint = (status) => {
    return toTitleCase(status)
}

const isTomorrow = (startingDate) => {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    return startingDate.toDateString() === tomorrow.toDateString()
}

export const getUpdatedStatus = (status, checkin, checkout) => {
    if (status.toLowerCase() !== 'confirmed') return statusPrint(status)
    const now = new Date()
    const checkinDate = new Date(checkin)
    const checkoutDate = new Date(checkout)

    if (isTomorrow(checkinDate)) return 'Arrives Tomorrow'
    if (checkinDate.toDateString() === now.toDateString()) return 'Checking In'
    if (checkoutDate.toDateString() === now.toDateString()) return 'Checking Out'
    if (now > checkinDate && now < checkoutDate) return 'Hosting'
    if (now > checkoutDate) return 'Completed'
    return statusPrint(status)
}

export function deepClone(obj) {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }
    if (Array.isArray(obj)) {
        return obj.map(deepClone);
    }
    const clonedObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            clonedObj[key] = deepClone(obj[key]);
        }
    }
    return clonedObj;
}

export function deepChanges(obj1, obj2, path = '') {
    let changes = {};
    if (obj1 === obj2) {
        return changes;
    }
    if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        changes[path] = obj2;
        return changes;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    const allKeys = new Set([...keys1, ...keys2]);

    allKeys.forEach(key => {
        const newPath = path ? `${path}.${key}` : key;
        if (!keys1.includes(key)) {
            changes[newPath] = obj2[key];
        } else if (!keys2.includes(key)) {
            changes[newPath] = undefined;
        } else {
            const nestedChanges = deepChanges(obj1[key], obj2[key], newPath);
            if (Object.keys(nestedChanges).length > 0) {
                changes = { ...changes, ...nestedChanges };
            }
        }
    });

    return changes;
}


export const provinces = ["--",
    "AB", "BC", "MB", "NB", "NL", "NS", "ON", "PE", "QC", "SK", "NT", "NU", "YT"
]

export const convertToNumber = (currencyString) => {
    let numericString = currencyString.replace(/[^\d.-]/g, '');
    let number = parseFloat(numericString);
    return number;
}

export const cancelPolicies = [
    {
        label: "Flexible",
        description: "Full refund up to 24 hours before check-in",
        value: "flexible"
    },
    {
        label: "Moderate",
        description: "Full refund up to 5 days before check-in",
        value: "moderate"
    },
    {
        label: "Firm",
        description: "Full refund up to 30 days before check-in",
        value: "firm"
    },
    {
        label: "Strict",
        description: "Full refund only within 48 hours of booking",
        value: "strict"
    }
];

export const addprotocol = (url) => {
    // Check if the URL starts with a valid protocol (http:// or https://)
    if (!/^https?:\/\//i.test(url)) {
        // If not, prepend https:// as a default protocol
        return 'https://' + url;
    }
    // Return the URL if it already has a valid protocol
    return url;
}
